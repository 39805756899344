import http from "@/common/http-common"
import { CONFIG, BASE_PAYMENTS, BASE_PAYMENT_REVIEW } from "@/common/constant";
import { BASE_PAYMENT_PURPOSES } from "@/common/constant";
import { BASE_BUDGET } from "@/common/constant";

class PaymentService {

  getAll(params) {
    return http.get(`${BASE_PAYMENTS}`,  {
      params: params
    });
  }

  //high performance
  getAllV2(params) {
    return http.get(`${BASE_PAYMENTS}/payments-v2`,  {
      params: params
    });
  }

  getAllDraft(params) {
    return http.get(`${BASE_PAYMENTS}/draft`,  {
      params: params
    });
  }

  
  /*Sebagian filter blm implemented di v2
  */
  getAllFilterBy(params, filterBy){
    const KEY="paymentFilterV2";
    //cek apakah v2 ON
    http.get(`${CONFIG}/user/PAYMENT_FILTER_V2`).then((config)=>{
      var versi2Enabled=config.data.configValue==='true';
      localStorage.setItem(KEY,`${versi2Enabled}`);
    });
      var versi2Enabled=localStorage.getItem(KEY)==='true';
      console.log(`FilterBy: ${filterBy}`)
      if(!versi2Enabled)
        return this.getAll(params);

      if(filterBy==null 
          || filterBy==''
          || filterBy=='vendor_name' 
          || filterBy=='display_id'
          || filterBy=='payment_process_id'
          || filterBy=='payment_field invoice')
      return this.getAllV2(params)
    else
      return this.getAll(params)
  }

  startWorkflowFromDraft(displayId) {
    displayId=displayId.replace("&","%26");
    return http.get(`${BASE_PAYMENTS}/startWorkflowFromDraft?displayId=${displayId}`);
  }

  get(id) {
    return http.get(`${BASE_PAYMENTS}/${id}`);
  }

  upsert(data, document, ignoreConstraintWarning=false) {
    let formData = new FormData();
    formData.append("payment", JSON.stringify(data))
    formData.append("document", document);
    formData.append("ignoreConstraintWarning", ignoreConstraintWarning);

    return http.post(`${BASE_PAYMENTS}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  updateGRSES(data) {
    let formData = new FormData();
    formData.append("payment", JSON.stringify(data))

    return http.post(`${BASE_PAYMENTS}/update-grses`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  startWorkflow(data, document, ignoreConstraintWarning=false) {
    let formData = new FormData();
    formData.append("payment", JSON.stringify(data))
    formData.append("document", document);
    formData.append("ignoreConstraintWarning", ignoreConstraintWarning);
    let paymentId=data.id;
    return http.post(`${BASE_PAYMENTS}/start`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }


  delete(id) {
    return http.delete(`${BASE_PAYMENTS}/${id}`);
  }

  export(){
    return http.get(BASE_PAYMENTS, {
      params: localStorage.getItem('bearer_token')
    });
  }

  getPaymentPurposes() {
    return http.get(BASE_PAYMENT_PURPOSES);
  }

  getPaymentPurpose(id) {
    return http.get(`${BASE_PAYMENT_PURPOSES}${id}`);
  }

  review(data, document) {
    let formData = new FormData();
    formData.append("payment", JSON.stringify(data))
    formData.append("document", document);

    return http.post(`${BASE_PAYMENTS}/review`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  uploadBpp(data, document) {
    let formData = new FormData();
    formData.append("payment", JSON.stringify(data))
    formData.append("document", document);

    return http.post(`${BASE_PAYMENTS}/review-field/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  count(params) {
    return http.get(`${BASE_PAYMENTS}/count`,  {
      params: params
    });
  }

  countGroupByPaymentProcess(params) {
    return http.get(`${BASE_PAYMENTS}/count/group-by/paymentProcessId`,  {
      params: params
    });
  }

  countGroupByUserDivision(params) {
    return http.get(`${BASE_PAYMENTS}/count/group-by/userDivisionId`,  {
      params: params
    });
  }

  alertMe(paymentId, type) {
    return http.post(`${BASE_PAYMENTS}/email/reminder/${paymentId}?email_type=${type}`);
  }

  docControlTimeout(paymentId) {
    console.log({paymentId});
    let formData = new FormData();
    formData.append("payment", JSON.stringify({paymentId: paymentId}));
    console.log({formData});

    return http.post(`${BASE_PAYMENTS}/timeoutDocumentUpload`, formData);
  }

  getTermOfPaymentList(){
    return http.get(`${BASE_PAYMENTS}/term-of-payments`);
  }

  reviewKirimDoc(data, document) {
    let formData = new FormData();
    formData.append("payment", JSON.stringify(data))
    formData.append("document", document);

    return http.post(`${BASE_PAYMENTS}/review-field/review-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  reviewKirimDocBulk(payments, document) {
    let formData = new FormData();
    formData.append("payments", JSON.stringify(payments))
    formData.append("document", document);

    return http.post(`${BASE_PAYMENTS}/review-field/review-document-bulk`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getSumClosings(id) {
    return http.get(`${BASE_PAYMENTS}/get-sum-closings/${id}`);
  }
}
export default new PaymentService();
