import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "./components/documentation/dashboard.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("./views/login.vue")
  },
  {
    path: "/login/:url",
    name: "login-url",
    component: () => import("./views/login.vue")
  },
  {
    path: "/",
    name: "dashboard",
    component: () => import("./components/documentation/dashboard.vue")
  },
  {
    path: "/roles",
    name: "Roles",
    component: () => import("./views/master-data/roles.vue")
  },
  {
    path: "/users",
    name: "User",
    component: () => import("./views/master-data/users.vue")
  },
  {
    path: "/currencies",
    name: "Currency",
    component: () => import("./views/master-data/currencies.vue")
  },
  {
    path: "/organizations",
    name: "Organization Chart",
    component: () => import("./views/master-data/organization.vue")
  },
  {
    path: "/vendors",
    name: "Vendors",
    component: () => import("./views/master-data/vendors.vue")
  },
  {
    path: "/budgets",
    name: "Budgets",
    component: () => import("./views/master-data/budgets.vue")
  },
  {
    path: "/payment-fields",
    name: "payments-fields",
    component: () => import("./views/master-data/payment-field.vue")
  },
  {
    path: "/pph",
    name: "pph",
    component: () => import("./views/master-data/pph.vue")
  },
  {
    path: "/payments",
    name: "payments",
    props: true,
    component: () => import("./views/payments/payments.vue")
  },
  {
    path: "/nonpo",
    name: "nonpo",
    props: true,
    component: () => import("./views/master-data/non-po.vue")
  },
  {
    path: "/payment/:paymentType/:action?/:paymentId?",
    name: "payment",
    component: () => import("./views/payments/payment.vue")
  },
  {
    path: "/download/:paymentId?/:fileName?",
    name: "download",
    component: () => import("./views/download.vue")
  },
  {
    path: "/payment-schedule",
    name: "payment-schedule",
    component: () => import("./views/master-data/payment-schedule.vue")
  },
  {
    path: "/payment-schedule-approval",
    name: "payment-schedule-approval",
    component: () => import("./views/master-data/payment-schedule-approval.vue")
  },
  {
    path: "/payment-schedule-approval/:paymentScheduleExtractId",
    name: "payment-schedule-approval-extract",
    component: () => import("./views/master-data/payment-schedule-approval.vue")
  },
  {
    path: "/bdv",
    name: "bdv",
    component: () => import("./views/bdv/bdv.vue")
  },
  {
    path: "/bdv/:action/:id",
    name: "bdv-detail",
    component: () => import("./views/bdv/bdv-detail.vue")
  },
  {
    path: "/help",
    name: "help",
    component: () => import("./views/help.vue")
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("./views/maintenance.vue")
  },
  {
    path: "/payments-export",
    name: "payments-export",
    props: true,
    component: () => import("./views/payments/payments-export.vue")
  },
  {
    path: "/documents",
    name: "documents",
    props: true,
    component: () => import("./views/documents/documents.vue")
  },
  {
    path: "/finance-notification",
    name: "finance-notification",
    component: () => import("./views/finance-notification.vue")
  },
  {
    path: "/config",
    name: "config",
    component: () => import("./views/config.vue")
  },
  {
    path: "/valas/config",
    name: "valas-config",
    component: () => import("./views/valas/config.vue")
  },
  {
    path: "/valas/withdrawal",
    name: "valas-withdrawal",
    component: () => import("./views/valas/withdrawal.vue")
  },
  {
    path:"/grses-edit",
    name:'grses-edit',
    component: ()=> import("./views/payments/grses-edit.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
